import axios from "axios";

async function getAllUsers() {
  try {
    let response = await axios.get("user");
    return response.data;
  } catch (error) {
    return error;
  }
}

async function createUser(data) {
  let body = {
    name: data.name,
    phone: data.phone,
    password: data.password,
    role: data.role,
    branch: data.branch,
  };
  try {
    const response = await axios.post("user", body);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function updateUser(id, data) {
  try {
    const response = await axios.patch(`user/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function makeActive(id) {
  try {
    const response = await axios.patch(`user/makeActive/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function makeInActive(id) {
  try {
    const response = await axios.patch(`user/makeInActive/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function removeUser(id) {
  try {
    const response = await axios.delete(`user/${id}`);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

export { getAllUsers, createUser, updateUser, makeActive, makeInActive, removeUser };
