<template>
  <div>
    <el-row type="flex" justify="space-between" align="middle">
      <el-col :span="10">
        <el-input placeholder="Search by phone or name" prefix-icon="el-icon-search" v-model="filters.searchString" />
      </el-col>
      <el-col :span="6">
        <el-button type="primary" icon="el-icon-circle-plus-outline" @click="onNewClick">New</el-button>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="24">
        <el-table ref="elTable" :data="filteredData" :row-key="getRowKey"
          :default-sort="{ prop: 'name', order: 'ascending' }" style="width: 100%" border size="small"
          :header-cell-style="{ background: '#f1f1f5', color: 'black', textAlign: 'center' }"
          :show-overflow-tooltip="true">
          <el-table-column property="name" label="Name" align="center" sortable />
          <el-table-column property="phone" label="Phone" align="center" />
          <el-table-column property="role" label="Role" align="center" sortable />
          <el-table-column property="branch" label="Branches" align="center" :filters="filters.branches"
            :filter-method="filterBranch">
            <template slot-scope="scope">
              <div v-for="item in scope.row.branch" :key="item.name">
                {{ item.name }}
                <br />
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center">
            <template slot-scope="scope">
              <el-dropdown trigger="click" @command="onMenuClick($event, scope.row)">
                <span class="el-dropdown-link"> Options <i class="el-icon-arrow-down el-icon--right"></i> </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item icon="el-icon-edit" command="EDIT">Edit</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-circle-close" v-if="scope.row.is_active"
                    command="BLOCK">Block</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-circle-close" v-else command="UNBLOCK">Unblock</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-refresh-left" command="REST_PASSWORD">Reset Password</el-dropdown-item>
                  <el-dropdown-item icon="el-icon-delete" command="REMOVE">Remove</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
    <!-- Dialog Components -->

    <!-- edit user -->
    <template>
      <el-dialog :title="title" :visible.sync="newUserDialog">
        <el-form label-position="right" :model="form" label-width="100px" :rules="rules" ref="ruleForm">
          <el-form-item label="Name" prop="name">
            <el-input v-model="form.name" autocomplete="off" />
          </el-form-item>
          <el-form-item label="Phone" prop="phone">
            <el-input v-model="form.phone" autocomplete="off" />
          </el-form-item>
          <el-form-item label="Password" prop="password" v-if="editIndex <= -1">
            <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Role" prop="role">
            <el-select v-model="form.role" placeholder="Select">
              <el-option v-for="role in roles" :key="role.value" :label="role.label" :value="role.value"> </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Branches" prop="branch">
            <el-select v-model="form.branch" multiple collapse-tags placeholder="Select">
              <el-option v-for="branch in branches" :key="branch.id" :label="branch.code" :value="branch.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="newUserDialog = false">Cancel</el-button>
          <el-button type="primary" @click="OnSave">Confirm</el-button>
        </span>
      </el-dialog>
    </template>
    <!-- end -->

    <!-- change password -->
    <template>
      <el-dialog title="Change user's password" :visible.sync="changePassword">
        <el-form :model="form" status-icon :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
          <el-form-item label="Password" prop="password">
            <el-input type="password" v-model="form.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="Confirm" prop="checkPass">
            <el-input type="password" v-model="form.checkPass" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
            <el-button @click="resetForm('ruleForm')">Reset</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </template>
    <!-- end -->

    <!-- End -->
  </div>
</template>

<script>
import { createUser, getAllUsers, updateUser, makeActive, makeInActive, removeUser } from "./lib";

import { RoleTypes } from "../../common/constants/RolesTypes";
import { mapGetters } from "vuex";

export default {
  name: "Users",
  watch: {
    newUserDialog(val) {
      if (!val) {
        this.form = {
          id: "",
          name: "",
          phone: "",
          role: "",
          password: "",
          branch: [],
        };
        this.$refs.ruleForm.resetFields();
      }
    },
  },
  computed: {
    ...mapGetters(["branches"]),
    filteredData() {
      let searchString = this.filters.searchString;
      return this.users.filter(function (item) {
        return (
          item.name.toUpperCase().indexOf(searchString.toUpperCase()) >= 0 ||
          item.phone.toUpperCase().indexOf(searchString.toUpperCase()) >= 0
        );
      });
    },
    title() {
      return this.editIndex > -1 ? "Edit User" : "Add new User";
    },
  },
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password'));
      } else {
        if (this.form.checkPass !== '') {
          this.$refs.ruleForm.validateField('checkPass');
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('Please input the password again'));
      } else if (value !== this.form.password) {
        callback(new Error('Two inputs don\'t match!'));
      } else {
        callback();
      }
    };
    return {
      users: [],
      roles: RoleTypes,
      filters: {
        searchString: "",
        branches: [],
      },
      form: {
        id: "",
        name: "",
        phone: "",
        role: "",
        password: "",
        checkPass: "",
        branch: [],
      },
      rules: {
        name: [{ required: true, message: "Please enter user name", trigger: "blur" }],
        phone: [
          { required: true, message: "Please enter phone number", trigger: "blur" },
          { pattern: /^(\+?\d{1,3}[-.\s]?)?\(?(\d{3})\)?[-.\s]?(\d{3})[-.\s]?(\d{4})$/, message: "Invalid Phone number", trigger: "blur" },
          { min: 10, message: "Length should be 10", trigger: "blur" },
        ],
        role: [{ required: true, message: "Please select role", trigger: "blur" }],
        branch: [{ type: "array", required: true, message: "Please select branch", trigger: "change" }],
        password: [
          { validator: validatePass, trigger: 'blur' }
        ],
        checkPass: [
          { validator: validatePass2, trigger: 'blur' }
        ],
      },
      editIndex: -1,
      isMounted: false,
      newUserDialog: false,
      changePassword: false
    };
  },
  methods: {
    async onInit() {
      this.users = await getAllUsers();
      this.setFilters()
    },
    getRowKey(row) {
      return row.id;
    },
    onNewClick() {
      this.editIndex = -1
      this.newUserDialog = true;
    },
    onMenuClick(command, row) {
      Object.assign(this.form, row, { branch: row.branch.map((b) => b.id) });
      this.editIndex = this.users.findIndex((x) => x.id === row.id);
      switch (command) {
        case "EDIT":
          this.newUserDialog = true;
          break;
        case "REST_PASSWORD":
          this.changePassword = true;
          break;
        case 'BLOCK':
          this.blockOrUnblockUser(command)
          break;
        case 'UNBLOCK':
          this.blockOrUnblockUser(command)
          break;
        case 'REMOVE':
          this.removeUser()
          break;
        default:
          break;
      }
    },

    async OnSave() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {

          const body = {
            name: this.form.name,
            phone: String(this.form.phone),
            branch: this.form.branch.map((b) => ({ id: b })),
            role: this.form.role,
            password: this.form.password
          };

          if (this.editIndex > -1) {
            this.onUserEditSave(body)
          } else {
            this.onUserCreateSave(body)
          }
          this.newUserDialog = false;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

    async onUserEditSave(data) {
      const res = await updateUser(this.form.id, data);
      this.users[this.editIndex] = res;
      this.$notify({
        title: "Success",
        text: 'User updated successfully.',
        type: "success",
      });
      await this.onInit();
    },
    async onUserCreateSave(data) {
      const res = await createUser(data);
      this.users[this.editIndex] = res;
      this.$notify({
        title: "Success",
        text: 'User created successfully.',
        type: "success",
      });
      await this.onInit();
    },
    async blockOrUnblockUser(action) {
      if (action == 'BLOCK') {
        await makeInActive(this.form.id)
        this.$notify({
          title: "Success",
          text: 'User unblocked successfully.',
          type: "success",
        });
        this.users[this.editIndex].is_active = false
      } else {
        await makeActive(this.form.id)
        this.$notify({
          title: "Success",
          text: 'User blocked successfully.',
          type: "success",
        });
        this.users[this.editIndex].is_active = true
      }
    },
    async removeUser() {
      await removeUser(this.form.id)
      this.$notify({
        title: "Success",
        text: 'User removed successfully.',
        type: "success",
      });
      await this.onInit();
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.onUserEditSave({ password: this.form.password })
          this.changePassword = false;
          this.resetForm('ruleForm')
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    setFilters() {
      this.filters.branches = [...new Set(this.users.flatMap(item => item.branch).map(item => item.name))].map(name => ({ text: name, value: name }))
    },
    filterBranch(value, row) {
      return row.branch.some(item => item.name.toLowerCase().includes(value.toLowerCase()));
    },
  },
  async mounted() {
    await this.$store.dispatch("setLoading", true);
    await this.onInit();
    this.isMounted = true;
    await this.$store.dispatch("setLoading", false);
  },
};
</script>
<style scoped>
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}

.el-icon-arrow-down {
  font-size: 12px;
}
</style>
